<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
// eslint-disable-next-line no-unused-vars
import AppLogo from "@/components/AppLogo.vue";

const router = useRouter();
const sectionInView = ref(false);

function navigateToLogin() {
  router.push({ name: "login" });
}

onMounted(() => {
  const observer = new IntersectionObserver(
    ([entry]) => {
      sectionInView.value = entry.isIntersecting;
      const section = document.querySelector(".section-2");
      if (section) {
        if (entry.isIntersecting) {
          section.classList.add("in-view");
        } else {
          section.classList.remove("in-view");
        }
      }
    },
    { threshold: 0.1 },
  );

  const section = document.querySelector(".section-2");
  if (section) {
    observer.observe(section);
  }
});
</script>

<template>
  <!-- Hero banner -->
  <v-container fluid class="landing-main_container">
    <section class="hero_section px-0">
      <div class="blur-container" style="--blur: 12vw">
        <div
          class="shape"
          style="
            --path: polygon(
              50.9% 37.2%,
              43.5% 34.7%,
              33.6% 26.1%,
              39.2% 10.8%,
              26.2% 0%,
              4.8% 6.4%,
              0% 30.4%,
              20.7% 37.2%,
              33.4% 26.3%,
              43.2% 34.9%,
              45% 35.6%,
              43.6% 46.4%,
              37.8% 59.5%,
              21.8% 63.2%,
              11.7% 76.1%,
              22.9% 91.3%,
              47.4% 91.3%,
              54% 79%,
              38% 59.6%,
              43.9% 46.4%,
              45.2% 35.5%,
              50.9% 37.6%,
              56.1% 36.8%,
              59.8% 47.6%,
              70.3% 61.9%,
              87.7% 56%,
              96.4% 37.4%,
              88.6% 15.1%,
              63.7% 16.7%,
              55.2% 33.6%,
              55.9% 36.6%,
              50.9% 37.2%
            );
          "
        ></div>

        <div
          class="shape"
          style="
            --path: polygon(
              50.9% 37.2%,
              43.5% 34.7%,
              33.6% 26.1%,
              39.2% 10.8%,
              26.2% 0%,
              4.8% 6.4%,
              0% 30.4%,
              20.7% 37.2%,
              33.4% 26.3%,
              43.2% 34.9%,
              45% 35.6%,
              43.6% 46.4%,
              37.8% 59.5%,
              21.8% 63.2%,
              11.7% 76.1%,
              22.9% 91.3%,
              47.4% 91.3%,
              54% 79%,
              38% 59.6%,
              43.9% 46.4%,
              45.2% 35.5%,
              50.9% 37.6%,
              56.1% 36.8%,
              59.8% 47.6%,
              70.3% 61.9%,
              87.7% 56%,
              96.4% 37.4%,
              88.6% 15.1%,
              63.7% 16.7%,
              55.2% 33.6%,
              55.9% 36.6%,
              50.9% 37.2%
            );
            --offset: 180deg;
            --speed: 6000ms;
            --background: linear-gradient(cyan, blue, green, purple, cyan);
          "
        ></div>
      </div>
      <v-sheet>
        <v-container class="d-flex align-center justify-center">
          <div class="d-flex align-center justify-center text-h6">
            <v-img
              src="@/assets/jellypic-symbol.svg"
              width="24"
              height="24"
              class="mx-2"
            />
            <span class="font-weight-bold">jellypic</span>&nbsp;
            {{ $t("landing-page.sharing-platform") }}
          </div>
        </v-container>

        <v-row>
          <v-col cols="12" class="text-center">
            <div class="text-h1 font-weight-light">
              {{ $t("landing-page.hero-line-one") }},<br />{{
                $t("landing-page.hero-line-two")
              }}
            </div>
            <div
              class="text-h6 my-4"
              v-html="$t('landing-page.hero-subtitle-paragraph')"
            ></div>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn
              color="primary"
              size="large"
              class="mx-2 gradient-button"
              rounded="xl"
              @click="navigateToLogin"
              >{{ $t("landing-page.get-started-with-google") }}</v-btn
            >
            <div
              class="text-overline mt-4"
              v-html="$t('landing-page.easy-log-in-no-credit-card-or-signup')"
            ></div>
          </v-col>
        </v-row>
      </v-sheet>
    </section>
    <section
      id="landing-features"
      class="hero_section section-2 px-0"
      :class="{ 'in-view': sectionInView }"
    >
      <v-sheet class="sheet-background-2">
        <v-row class="pa-10">
          <!--
          <v-col cols="8">
            <div class="text-h2 gradient-text">
              {{ $t("landing-page.features-title") }}
            </div>
          </v-col>
          <v-col cols="4">
            <div class="text-h2 gradient-text">
              {{ $t("landing-page.no-ia-training-title") }}
            </div>
          </v-col>
          -->
          <v-col cols="8">
            <v-card class="mx-auto" elevation="16">
              <v-img
                src="@/assets/images/landing/pexels-felix-young-449360607-30246614.jpg"
                height="150"
                cover
              >
                <div class="text-h1 text-white">
                  {{ $t("landing-page.features-title") }}
                </div></v-img
              >
              <v-card-text>
                <v-list>
                  <v-list-item>
                    <template v-slot:prepend>
                      <v-icon icon="mdi-link-lock"></v-icon>
                    </template>
                    <v-list-item-title>
                      {{
                        $t("landing-page.features-share-your-projects-title")
                      }}</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      $t("landing-page.features-share-your-projects-subtitle")
                    }}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <template v-slot:prepend>
                      <v-icon icon="mdi-shield-lock-outline"></v-icon>
                    </template>
                    <v-list-item-title
                      >{{
                        $t("landing-page.features-protect-your-creations-title")
                      }} </v-list-item-title
                    ><v-list-item-subtitle>{{
                      $t(
                        "landing-page.features-protect-your-creations-subtitle",
                      )
                    }}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <template v-slot:prepend>
                      <v-icon icon="mdi-account-lock-outline"></v-icon>
                    </template>
                    <v-list-item-title>{{
                      $t("landing-page.features-gain-insights-title")
                    }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t("landing-page.features-gain-insishts-subtitle") }}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <template v-slot:prepend>
                      <v-icon icon="mdi-timer-off-outline"></v-icon>
                    </template>
                    <v-list-item-title>{{
                      $t("landing-page.features-you-are-in-control-title")
                    }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{
                        $t("landing-page.features-you-are-in-control-subtitle")
                      }}</v-list-item-subtitle
                    >
                  </v-list-item>
                  <v-list-item>
                    <template v-slot:prepend>
                      <v-icon icon="mdi-eye-outline"></v-icon>
                    </template>
                    <v-list-item-title
                      >{{ $t("landing-page.features-engage-title") }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{
                      $t("landing-page.features-engage-subtitle")
                    }}</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
                <v-divider class="my-4"></v-divider>
                <p class="text-caption">
                  {{ $t("landing-page.features-list-notes-one") }}
                </p>
                <p class="text-caption">
                  {{ $t("landing-page.features-list-notes-two") }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card class="mx-auto" elevation="16">
              <v-img
                src="@/assets/images/landing/pexels-arts-1853542.jpg"
                height="300"
                cover
              >
                <div class="text-h1 text-white">
                  {{ $t("landing-page.no-ia-training-title") }}
                </div>
              </v-img>
              <v-card-text>
                <p>
                  {{ $t("landing-page.features-no-ai-head-text") }}

                  {{ $t("landing-page.features-no-ai-body") }}
                </p>

                <p class="font-weight-bold my-4">
                  {{ $t("landing-page.features-no-ai-closure") }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-sheet>
    </section>

    <section class="hero_section section-3 px-0" id="landing-about">
      <v-sheet class="sheet-background-3">
        <v-row class="pa-10">
          <v-col>
            <div class="text-h1">{{ $t("landing-page.about-jellypic") }}</div>
            <p v-html="$t('landing-page.about-first-paragraph')"></p>
            <p
              class="my-4"
              v-html="$t('landing-page.about-second-paragraph')"
            ></p>
            <v-list>
              <v-list-item>
                <template v-slot:prepend>
                  <v-icon icon="mdi-calendar-clock"></v-icon>
                </template>
                <v-list-item-title
                  >Schedule access to your collections</v-list-item-title
                >
                <v-list-item-subtitle>
                  Protect with PINs or distribute via unique links or QR codes.
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <template v-slot:prepend>
                  <v-icon icon="mdi-timer-off-outline"></v-icon>
                </template>
                <v-list-item-title>Set view limits</v-list-item-title>
                <v-list-item-subtitle>
                  Ensure automatic expirations to prevent overexposure.
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <template v-slot:prepend>
                  <v-icon icon="mdi-shield-lock-outline"></v-icon>
                </template>
                <v-list-item-title
                  >Safeguard against unauthorized AI scraping</v-list-item-title
                >
                <v-list-item-subtitle>
                  Advanced security measures.
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <template v-slot:prepend>
                  <v-icon icon="mdi-account-lock-outline"></v-icon
                ></template>
                <v-list-item-title
                  >Protecting Your Creative Integrity</v-list-item-title
                >
                <v-list-item-subtitle>
                  As fellow professionals, we understand the frustration of
                  uncredited or unauthorized image use.
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>

            <p
              class="my-4"
              v-html="$t('landing-page.our-promise-paragraph')"
            ></p>
          </v-col>
        </v-row>
      </v-sheet>
    </section>
    <section class="hero_section section-4 px-0" id="landing-contact">
      <v-sheet style="border: 5px solid silver">
        <v-row class="pa-10">
          <v-col cols="12" class="text-center">
            <div class="text-h1">Contact</div>
            <p v-html="$t('landing-page.contact-us-paragraph')"></p>
            <p>We speak:</p>
            <v-container class="d-flex align-center justify-center">
              <v-img
                src="https://flagicons.lipis.dev/flags/4x3/gb.svg"
                width="24"
                height="16"
              ></v-img>
              <v-img
                src="https://flagicons.lipis.dev/flags/4x3/vn.svg"
                width="24"
                height="16"
              ></v-img>
              <v-img
                src="https://flagicons.lipis.dev/flags/4x3/fr.svg"
                width="24"
                height="16"
              ></v-img
              ><v-img
                src="https://flagicons.lipis.dev/flags/4x3/it.svg"
                width="24"
                height="16"
              ></v-img
            ></v-container>
          </v-col>
        </v-row>
      </v-sheet>
    </section>
  </v-container>
</template>

<style scoped lang="scss">
.landing-main_container {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 0;
}

.main_container::-webkit-scrollbar {
  display: none;
}

section {
  scroll-snap-align: start;
}

.hero_section {
  scroll-snap-align: start;
  height: 100vh;
  width: 100hw;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  position: relative;
  background-color: white;
}

.section-2 {
  background-color: transparent !important;
}
.section-2:before {
  background: url(../assets/images/landing/pexels-pixabay-316093.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(40px);
  transition: filter 2s ease-in-out;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.section-2.in-view:before {
  filter: blur(0px);
}

.section-3 {
  background-color: transparent !important;
  background: url(../assets/images/landing/pexels-scottwebb-1084510.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section-4 {
  background-color: transparent !important;
  background: url(../assets/images/landing/pexels-brett-sayles-26545626.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.sheet-background-2 {
  background-color: transparent !important;
  backdrop-filter: blur(0px) !important;
}

.sheet-background-3 {
  background-color: rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: blur(16px) !important;
}

.v-list {
  background-color: transparent !important;
}

.blur-container {
  overflow: hidden;
  --blur: 80px;
  opacity: 0.5;
  filter: blur(var(--blur));
  height: 100vh;
  width: 100%;
  position: absolute;
  display: grid;
  > * {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }
}
.shape {
  margin: 0 auto;
  aspect-ratio: 1;
  position: absolute;

  --width: 100%;
  --scale: 1;
  --opacity: 0.66;
  --top: 0;
  --left: 0;
  --path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  --background: linear-gradient(hotpink, red, orange, yellow, hotpink);

  --offset: 0deg;
  --speed: 60000ms;

  clip-path: var(--path);
  background: var(--background);
  scale: var(--scale);
  opacity: var(--opacity);
  width: var(--width);
  top: var(--top);
  left: var(--left);
  rotate: var(--offset);

  mix-blend-mode: difference;

  animation: turn var(--speed) linear forwards infinite;

  @keyframes turn {
    to {
      rotate: calc(var(--offset) + 1turn);
    }
  }
}

.horizontal-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.gradient-button {
  background: linear-gradient(45deg, #005f73, #0a9396, #94d2bd, #005f73);
  background-size: 200% 200%;
  animation: gradient-animation 7s ease infinite;
  border: none;
  color: white;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 100%;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient-text {
  /*background: linear-gradient(45deg, #a8e6cf, #dcedc1, #ffd3b6, #ffaaa5, #a8e6cf);
  background-size: 200% 200%;
  animation: gradient-animation 5s ease infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;*/
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
