import { getContainer, getFiles, getStars } from "@/api/container.client";
import { getShare } from "@/api/share.client";
import { getViewerFileTags, getViewerTags } from "@/api/tag.client";
import { defineStore } from "pinia";

export const useViewerStore = defineStore({
  id: "viewer",

  state: () => ({
    share: {},
    container: {},
    items: {},
    stars: {},
    tags: [],
    fileTags: [],
  }),

  getters: {
    getShare(state) {
      return state.share;
    },

    getContainer(state) {
      return state.container;
    },

    getItems(state) {
      return state.items;
    },

    getStars(state) {
      return state.stars;
    },

    getTags(state) {
      return state.tags;
    },
  },

  actions: {
    async loadData(shareId) {
      await this.loadShare(shareId);
      await this.loadContainer(this.share.container);
      await this.loadFiles(this.container.id);
      await this.loadStars(this.container.id);

      const fileIds = this.items.map((i) => i.id);
      await this.loadTags(fileIds);
      await this.loadFileTags(fileIds);
    },

    async loadShare(shareId) {
      try {
        if (process.env.NODE_ENV === "development") {
          console.log("LoadShare");
        }

        const result = await getShare(shareId);
        this.share = result.data;
      } catch (exception) {
        if (process.env.NODE_ENV === "development") {
          console.error("LoadShare: ", exception);
        }

        throw exception;
      }
    },

    async loadContainer(containerId) {
      try {
        if (process.env.NODE_ENV === "development") {
          console.log("LoadContainer");
        }

        const result = await getContainer(containerId);
        this.container = result.data;
      } catch (exception) {
        if (process.env.NODE_ENV === "development") {
          console.error("LoadContainer: ", exception);
        }

        throw exception;
      }
    },

    async loadFiles(contaierId) {
      try {
        if (process.env.NODE_ENV === "development") {
          console.log("LoadFiles");
        }

        const result = await getFiles(contaierId);
        this.items = result.data;
      } catch (exception) {
        if (process.env.NODE_ENV === "development") {
          console.error("LoadFiles: ", exception);
        }

        throw exception;
      }
    },

    async loadStars(contaierId) {
      try {
        if (process.env.NODE_ENV === "development") {
          console.log("LoadStars");
        }

        const result = await getStars(contaierId);
        this.stars = result.data;
      } catch (exception) {
        if (process.env.NODE_ENV === "development") {
          console.error("LoadStars: ", exception);
        }

        throw exception;
      }
    },

    async loadTags(fileIds) {
      try {
        if (process.env.NODE_ENV === "development") {
          console.log("LoadTags");
        }

        const result = await getViewerTags(fileIds);
        this.tags = result.data;
      } catch (exception) {
        if (process.env.NODE_ENV === "development") {
          console.error("LoadTags: ", exception);
        }

        throw exception;
      }
    },

    async loadFileTags(fileIds) {
      try {
        if (process.env.NODE_ENV === "development") {
          console.log("LoadTags");
        }

        const result = await getViewerFileTags(fileIds);
        this.fileTags = result.data;
      } catch (exception) {
        if (process.env.NODE_ENV === "development") {
          console.error("LoadTags: ", exception);
        }

        throw exception;
      }
    },
  },
});
