// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        colors: {
          primary: "#32a852", // Verde acido (simile allo stile di Wise)
          secondary: "#1a7f43", // Verde scuro complementare al primary
          accent: "#82e291", // Verde più chiaro per un tocco complementare
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FFC107",
        },
      },
      dark: {
        colors: {
          primary: "#32a852", // Verde acido
          secondary: "#1a7f43", // Verde scuro complementare
          accent: "#82e291", // Verde chiaro
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FB8C00",
        },
      },
    },
  },
});
