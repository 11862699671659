/* eslint-disable prettier/prettier */
export default {
  install(app) {
    app.config.globalProperties.$appName = "jellypic";
    app.config.globalProperties.$appVersion = "0.7.0";
    app.config.globalProperties.$appMilestone = "MVP v.one";
  },
};

// Set it to try to redirect the calls to the Azure front end
export const $useRemoteApi = false;
// Shows a small banner with the current breakpoint name if set to true and in dev mode
export const $showCurrentBreakpoint =
  false && process.env.NODE_ENV === "development";

if($useRemoteApi && process.env.NODE_ENV === 'development') {    
    console.info("Using local API 💥💥");
} else {
    console.warn("Using REMOTE API 🛸🛸");
}

export const $linkHMACSecretKey = "Nel mezzo del cammin di nostra vita mi ritrovai per una selva oscura";
// Synonims - since we can have different products we need to have a way to change the name of the main object we move around
// so a container can became "Album, release, photos, etc"
export const $mainContainerDispayName = "Album";
export const $mainContainerDispayNamePlural = "Albums";
export const $mainObjectDispayName = "photo";
export const $mainObjectDispayNamePlural = "photos";
export const $baseUrl = window.location.origin;
export const $shareUrlBase = $baseUrl + "/view/";
export const $fileSizeLimit = (1024*1024) * 16; // In bytes
export const $acceptedExtensionsString = [".jpg", ".jpeg", ".png", ".gif", ".webp", ".svg"];
export const $reducedDrawerWidth = 300;
export const $expandedDrawerWidth = 500;

// If we want to show some components only to the viewer
export const $viewerOnly = false;
