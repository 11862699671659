<script setup>
import { defineExpose, onMounted } from "vue";
import { useShepherd } from "vue-shepherd";

const tour = useShepherd({
  useModalOverlay: true,
});

onMounted(() => {
  tour.addStep({
    title: "🙋‍♀️ <b>Welcome!</b>&nbsp;",
    closeButton: true,
    text: "Let’s take a quick tour and take a moment to explore this album.<br /><br />In this short tour you will learn how to navigate through the album, view photos, like photos, and more.",
    arrow: false,
    buttons: [
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    attachTo: { element: "#container-browser", on: "top" },
    closeButton: true,
    title: "📸&nbsp;<b>Photos</b>&nbsp;view",
    text: "Here you can browse through all the photos in this album. Click on any photo to view it in full screen. To show your appreciation, simply press the heart ❤️ icon to like a photo.<br /><br />The artist will see your appreciation in his dashboard.",
    arrow: false,
    buttons: [
      {
        text: "Next",
        action: tour.next,
      },
    ],
  });
  tour.addStep({
    attachTo: { element: "#container-browser-head-actions-starred", on: "top" },
    title: "❤️&nbsp;View only&nbsp;<b>liked</b>&nbsp;photos",
    text: "Click this icon to filter and view only the photos you've liked or all.",
    arrow: true,
    buttons: [
      {
        text: "Next",
        action: tour.next,
      },
      {
        text: "Back",
        action: tour.back,
      },
    ],
  });
  tour.addStep({
    attachTo: { element: "#container-browser-head-actions-sort", on: "bottom" },
    title: "🔍&nbsp;Sort photos",
    text: "Click this icon to sort the photos by name acending or descending.",
    arrow: true,
    buttons: [
      {
        text: "Next",
        action: tour.next,
      },
      {
        text: "Back",
        action: tour.back,
      },
    ],
  });
  tour.addStep({
    attachTo: {
      element: "#container-browser-head-actions-view-mode",
      on: "left",
    },
    title: "🖼️&nbsp;Change&nbsp;<b>view mode</b>",
    text: "This button toggle the view between grid and list view.",
    arrow: true,
    buttons: [
      {
        text: "Next",
        action: tour.next,
      },
      {
        text: "Back",
        action: tour.back,
      },
    ],
  });
  tour.addStep({
    title: "🎉&nbsp;<b>That's it!</b>",
    text: "You're all set to make the most of this album. Enjoy!",
    arrow: true,
    buttons: [
      {
        text: "All right! Let's go 🚀",
        action: tour.complete,
      },
    ],
  });
});

// Define the startTour function
function startTour() {
  tour.start();
}

// Expose the function
defineExpose({
  startTour,
});
</script>

<template>
  <span></span>
</template>

<style>
.shepherd-arrow {
  border: none;
}

.shepherd-arrow:before {
  border-bottom: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
}
</style>