import axios from "axios";
import { $useRemoteApi } from "@/plugins/globalProperties";

export const authInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production" || $useRemoteApi
      ? "https://oauth.graybay-4c50acea.northeurope.azurecontainerapps.io/"
      : "https://localhost:7083/",
  json: true,
});

export const signInAsync = async (exchangeCodeModel) => {
  return await authInstance.post("/auth/google", exchangeCodeModel);
};

export const refreshAsync = async (refreshTokenModel) => {
  return await authInstance.put("/auth/google", refreshTokenModel);
};

export const validateReCaptcha = async (token, username) => {
  return await authInstance.post(
    "/validatecaptcha/checktoken",
    {
      token,
      username,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};
