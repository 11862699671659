<template>
  <BaseTile ref="baseTile" :item="item">
    <template #preview>
      <!-- Custom content for the preview slot -->
      <v-img
        :src="imageUrl(item.path, 300)"
        :srcset="generateImageSrcSet(item.path)"
        :sizes="generateImageSizes()"
        class="d-flex align-center justify-center ma-2"
        @error="onImageError"
      >
        <template v-slot:placeholder>
          <v-container class="d-flex justify-center align-center h-100">
            <v-icon
              icon="mdi-image"
              size="small"
              color="grey"
              class="text-center"
            ></v-icon>
          </v-container>
        </template>

        <template v-slot:error>
          <v-container class="d-flex justify-center align-center h-100">
            <v-icon
              icon="mdi-alert-circle"
              size="small"
              color="grey"
              class="text-center"
            ></v-icon>
          </v-container>
        </template>
      </v-img>
    </template>
  </BaseTile>
</template>

<script setup>
import { ref } from "vue";
import BaseTile from "./BaseTile.vue";
import { imageUrl } from "@/api/image.client";
import {
  generateImageSizes,
  generateImageSrcSet,
} from "@/helpers/image.helper";

const baseTile = ref(null);
const hasError = ref(false);

// eslint-disable-next-line
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const onImageError = () => {
  hasError.value = true;
};
</script>
