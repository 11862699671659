<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { fileComplaint, alive } from "@/api/sales.client";
import { useAuthStore } from "@/stores/auth.store";

const hasError = ref(false);
const errorInfo = ref(null);
const showSnackbar = ref(false);
const authStore = useAuthStore();
const userIp = ref("");
const userLocale = ref(navigator.language || "unknown");
const screenResolution = ref(`${window.screen.width}x${window.screen.height}`);
const machineType = ref(navigator.platform || "unknown");

const getUserIp = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    userIp.value = data.ip;
  } catch (error) {
    console.error("Error fetching user IP:", error);
  }
};

const handleError = async (error, info) => {
  try {
    console.error("Error caught by boundary:", error);
    console.error("Additional info:", error, info);
    hasError.value = true;
    errorInfo.value = info || (error && error.message);
    showSnackbar.value = true;

    const isAlive = await checkAlive();
    if (!isAlive) return;

    const complaint = {
      id: 0,
      Error: JSON.stringify({ message: error.message, stack: error.stack }),
      Info: info,
      Uri: window.location.href,
      UserMail: authStore.userEmail, // Get the email of the current user
      Ip: userIp.value, // Get the user's IP address
      Locale: userLocale.value, // Get the user's locale
      ScreenResolution: screenResolution.value, // Get the screen resolution
      MachineType: machineType.value, // Get the machine type
      UserAgent: navigator.userAgent, // Get the user agent
      UserAgentVersion: navigator.appVersion, // Get the user agent version
    };

    try {
      // Now try to file a complaint with our dealer
      await fileComplaint(complaint);
    } catch (catchError) {
      // Do nothing if we can't file a complaint
    }
  } catch (catchError) {
    console.error("Error while handling another error:", catchError);

    // If there is an error while handling another error, we can't do much
  }
};

const handleErrorGlobal = (event) => {
  handleError(event.error, "Unhandled error");
};

const handleErrorPromise = (event) => {
  handleError(event.reason, "Unhandled promise rejection");
};

const setupGlobalOnError = () => {
  window.onerror = (message, source, lineno, colno, error) => {
    handleError(error || new Error(message), `${source}:${lineno}:${colno}`);
  };

  window.addEventListener("error", handleErrorGlobal);
  window.addEventListener("unhandledrejection", handleErrorPromise);
};

const closeSnackbar = () => {
  showSnackbar.value = false;
};

const checkAlive = async () => {
  try {
    await alive();
  } catch (error) {
    console.error("Alive check failed:", error);
    return false;
  }
  return true;
};

onMounted(async () => {
  setupGlobalOnError();
  getUserIp();
});

onBeforeUnmount(() => {
  window.onerror = null;
  window.removeEventListener("error", handleErrorGlobal);
  window.removeEventListener("unhandledrejection", handleErrorPromise);
});
</script>

<template>
  <div>
    <v-snackbar v-model="showSnackbar" :timeout="5000" bottom color="warn">
      <div class="snackbar-content">
        <span
          >We’re sorry, but something didn’t work as expected. Please try again
          later, and if the issue continues, feel free to contact our support
          team for assistance.</span
        >
        <!-- Cross icon button -->
        <v-icon @click="closeSnackbar" class="close-icon">mdi-close</v-icon>
      </div>
    </v-snackbar>
    <slot />
  </div>
</template>

<style scoped>
.snackbar-content {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.close-icon {
  cursor: pointer;
}
</style>
