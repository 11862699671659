<script setup>
import { useI18n } from "vue-i18n";

const { locale } = useI18n();

const setLocale = (newLocale) => {
  locale.value = newLocale;
};
</script>

<template>
  <v-list density="compact" v-model="$i18n.locale">
    <v-list-item
      v-for="locale in $i18n.availableLocales"
      :key="`locale-${locale}`"
      :value="locale"
      @click="setLocale(locale)"
    >
      <template v-slot:prepend>
        <v-img
          src="https://flagicons.lipis.dev/flags/4x3/gb.svg"
          width="24"
          height="16"
          class="mr-2"
          v-if="locale === 'en'"
        ></v-img>
        <v-img
          src="https://flagicons.lipis.dev/flags/4x3/vn.svg"
          width="24"
          height="16"
          class="mr-2"
          v-if="locale === 'vi'"
        ></v-img>
        <v-img
          src="https://flagicons.lipis.dev/flags/4x3/fr.svg"
          width="24"
          height="16"
          class="mr-2"
          v-if="locale === 'fr'"
        ></v-img>
        <v-img
          src="https://flagicons.lipis.dev/flags/4x3/it.svg"
          width="24"
          height="16"
          class="mr-2"
          v-if="locale === 'it'"
        ></v-img>
      </template>
      <v-list-item-title :value="locale" class="ml-6">
        {{ $t(`locale-selector.${locale}`) }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>
