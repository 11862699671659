<template>
  <v-expansion-panels
    v-if="showSelectionTipsBanner && selectionMode"
    style="padding: 2rem"
  >
    <v-expansion-panel variant="popout">
      <v-expansion-panel-title disable-icon-rotate>
        Psst! We've got some tips for the selection mode. Click here to view
        them.
        <template v-slot:actions>
          <v-btn @click="closeBanner" icon="mdi-close" size="24" />
        </template>
      </v-expansion-panel-title>

      <v-expansion-panel-text>
        <p>
          On both <b>Windows</b> and <b>Mac</b>, you can easily select multiple
          items using your keyboard and mouse:
          <br />
          🖱️ Click on a single item to select it.
          <br />
          ⇧ <b>Shift + Click</b> lets you select a range of items between two
          clicks.
          <br />
          ⌨️ Use <b>Ctrl + Click</b> (Windows) or <b>Command + Click</b> (Mac)
          to select multiple individual items.
          <br />
          🚀 Combine these shortcuts to manage your selections quickly and
          efficiently!
        </p>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>

  <PleaseWait class="please-wait-positioning"></PleaseWait>

  <!-- Container for when we have no items -->
  <v-container
    class="d-flex align-center justify-center no-select"
    v-if="files.length === 0"
  >
    <v-empty-state
      image="https://vuetifyjs.b-cdn.net/docs/images/components/v-empty-state/astro-dog.svg"
      :text="t('container-browser.empty-view-tip-message')"
      :title="t('container-browser.no-photos-yet-lets-change-that')"
    >
    </v-empty-state>
  </v-container>

  <template v-if="files.length > 0">
    <ContainerBrowserHead
      v-model:viewMode="viewMode"
      v-model:sortOrder="sortOrder"
      v-model:search="searchQuery"
      v-model:showOnlyStarred="showOnlyStarred"
      v-bind:title="containerName"
    ></ContainerBrowserHead>

    <!-- Real container of the items -->
    <v-container
      id="container-browser"
      v-if="viewMode === 'grid'"
      class="d-flex align-content-start flex-wrap no-select justify-center"
      @click.self="handleEmptyAreaClick"
    >
      <template v-for="item in filteredFilesList" :key="item.id">
        <template v-if="item.type === 'File'"
          ><FileTile
            :canBeSelected="selectionMode"
            :canBeStarred="allowStarring"
            :canBeDeleted="allowDelete"
            :canBeDownloaded="allowDownload"
            :item="item"
            :selected="selectedItems.some((i) => i.id === item.id)"
            :useDynamicSizing="useDynamicSizing"
            :starred="isStarred(item.block)"
            @click="handleItemClick($event, item)"
            @star="handleStarClick(item)"
            @delete="handleDeleteClick(item)"
            @download="handleDownloadClick(item)"
          ></FileTile
        ></template>
      </template>
    </v-container>
    <!-- And the container for the list view -->
    <v-container v-if="viewMode === 'list'" @click.self="handleEmptyAreaClick">
      <v-data-table
        v-model="selectedItems"
        v-model:search="searchQuery"
        :items="files"
        :headers="headers"
        item-key="id"
        return-object
        :show-select="selectionMode"
      >
        <template v-slot:item.id="{ item }">
          <v-container class="d-flex pa-0">
            <v-img
              :src="imageUrl(item.path, 300)"
              :srcset="generateImageSrcSet(item.path)"
              :sizes="generateImageSizes()"
              height="200"
              width="200"
              :alt="item.name"
              cover
              @click="handleItemClick($event, item)"
              style="cursor: hand"
            ></v-img>
            <v-container class="d-flex flex-column">
              <span>{{ item.name }}</span>
              <span class="text-caption"
                >Original name: {{ item.originalName }}</span
              >
              <v-textarea
                v-if="allowDescriptionChanges"
                :label="t('description')"
                hint="A free description for your file, visible to the end users"
                v-model="item.description"
                required
                :counter="400"
                @update:focused="
                  (focused) => updateDescriptionOnFocusOut(item, focused)
                "
                variant="plain"
                class="my-2"
              ></v-textarea>
              <span v-else class="text-caption">{{ item.description }}</span>
            </v-container>
          </v-container>
        </template>
        <template v-slot:item.stars="{ item }">
          <v-container class="d-flex align-center">
            <v-icon
              aria-label="Starred icon"
              aria-hidden="false"
              class="mr-1"
              size="small"
              :color="getStarCount(item.block) > 0 ? 'error' : ''"
              :icon="
                getStarCount(item.block) > 0 ? 'mdi-heart' : 'mdi-heart-outline'
              "
            ></v-icon>
            {{ getStarCount(item.block) }}
          </v-container>
        </template>
        <template v-slot:item.tags="{ item }">
          <TagSelector
            :viewerMode="viewerMode"
            :fileId="item.id"
            :tags="tags"
            :fileTags="fileTags"
          />
        </template>
        <template v-slot:item.stars="{ item }" v-if="!viewerMode">
          <span
            :style="{
              color: getStarCount(item.block) === 0 ? 'silver' : 'inherit',
            }"
          >
            {{ getStarCount(item.block) }}
          </span>
        </template>
      </v-data-table>
    </v-container>

    <v-container
      class="d-flex flex-column align-center justify-center no-select"
      v-if="hasStarredFiles"
    >
      <v-tooltip location="top">
        <template #activator="{ props }">
          <v-btn
            size="small"
            variant="plain"
            prepend-icon="mdi-download"
            @click="handleDownloadAllClick"
            v-bind="props"
          >
            {{ $t("container-browser.download-liked-photos-as-text") }}
          </v-btn>
        </template>
        <span v-html="t('container-browser.download-csv-tooltip')"> </span>
      </v-tooltip>

      <p class="text-caption">
        {{ files.length }} files, {{ calculateTotalSize(files) }}
        {{ $t("container-browser.data-retrieved-on") }}
        {{ getCurrentDateTime() }}
      </p>
    </v-container>

    <SelectionProperties
      v-if="!viewerMode"
      ref="selectionProperties"
      id="selection-properties"
      :items="selectedItems"
      :selectedItem="selectedItem"
      :containerName="containerName"
    ></SelectionProperties>
  </template>
</template>

<script setup>
import {
  ref,
  watch,
  computed,
  defineEmits,
  defineProps,
  defineModel,
  onMounted,
  getCurrentInstance,
} from "vue";
import ContainerBrowserHead from "@/components/ContainerBrowserHead";
import PleaseWait from "@/components/PleaseWait";
import { useI18n } from "vue-i18n";
import FileTile from "@/components/tiles/FileTile";
import SelectionProperties from "@/components/SidePanels/SelectionProperties";
import { updateFileDescription } from "@/api/file.client";
import { imageUrl } from "@/api/image.client";
import {
  generateImageSizes,
  generateImageSrcSet,
} from "@/helpers/image.helper";
import { calculateTotalSize } from "@/plugins/utils";
import TagSelector from "./TagSelector.vue";
import { useTagStore } from "@/stores/tag.store";
import { storeToRefs } from "pinia";
import { useViewerStore } from "@/stores/viewer.store";

const { t } = useI18n();

// Get access at the this. objects
const { appContext } = getCurrentInstance();
const global = appContext.config.globalProperties;

const tagStore = useTagStore();
const tag = storeToRefs(tagStore);

const viewerStore = useViewerStore();
const viewer = storeToRefs(viewerStore);

const tags = computed(() => {
  return props.viewerMode ? viewer.tags.value : tag.tags.value;
});

const fileTags = computed(() => {
  return props.viewerMode ? viewer.fileTags.value : tag.fileTags.value;
});

const viewMode = ref("grid");
const sortOrder = ref("asc");
const searchQuery = ref("");
const showOnlyStarred = ref(false);
const originalImageDescription = ref(null);

const files = defineModel("files", {
  type: Array,
  default: [],
});

const filteredFilesList = ref([]);

watch(
  files,
  () => {
    filteredFilesList.value = files.value;
  },
  { immediate: true },
);

const selectedItems = defineModel("selectedItems", {
  type: Array,
  default: [],
});

const selectedItem = computed(() => {
  return selectedItems.value.length > 0
    ? selectedItems.value[selectedItems.value.length - 1]
    : null;
});

const emit = defineEmits([
  "selectionChanged",
  "itemClick",
  "star",
  "delete",
  "download",
  "selectionBannerClosed",
]);

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  selectionMode: {
    type: Boolean,
    default: false,
  },
  showSelectionTipsBanner: {
    type: Boolean,
    default: false,
  },
  allowStarring: {
    type: Boolean,
    default: true,
  },
  allowDelete: {
    type: Boolean,
    default: true,
  },
  allowDownload: {
    type: Boolean,
    default: true,
  },
  allowDescriptionChanges: {
    type: Boolean,
    default: true,
  },
  useDynamicSizing: {
    type: Boolean,
    required: false,
    default: false,
  },
  stars: {
    type: Array,
    required: true,
  },
  containerName: {
    type: String,
    default: "",
  },
  viewerMode: {
    type: Boolean,
    default: false,
  },
});

// Watch for changes in the props
watch(
  () => props.selectionMode,
  (newVal) => {
    // Execute your code here
    if (newVal) {
      // Code to execute when selectionMode is true
    } else {
      selectedItems.value = [];
    }
  },
);

// eslint-disable-next-line no-unused-vars
watch(viewMode, (newVal, oldVal) => {
  console.log("View mode changed", viewMode.value);

  if (newVal !== "grid" && newVal !== "list") {
    console.error(
      `Invalid viewMode: ${newVal}. It must be either "grid" or "list".`,
    );
    return;
  }
});

// eslint-disable-next-line no-unused-vars
watch(sortOrder, (newVal, oldVal) => {
  console.log("Sort order changed", sortOrder.value);

  if (newVal !== "asc" && newVal !== "desc") {
    console.error(
      `Invalid sortOrder: ${newVal}. It must be either "asc" or "desc".`,
    );
    return;
  }

  files.value.sort((a, b) => {
    if (newVal === "asc") {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  });
});

// eslint-disable-next-line no-unused-vars
watch(searchQuery, (newVal, oldVal) => {
  filterView();
});

// eslint-disable-next-line no-unused-vars
watch(showOnlyStarred, (newVal, oldVal) => {
  filterView();
});

// Controls refs
const selectionProperties = ref(null);

const headers = [
  {
    title: t("container-browser.picture"),
    key: "id",
    sortable: true,
    align: "left",
  },
  {
    title: t("container-browser.tags"),
    key: "tags",
    sortable: false,
    align: "left",
  },
  {
    title: t("container-browser.size"),
    key: "size",
    value: "sizeAsString",
    align: "end",
  },
];

onMounted(() => {
  if (!props.viewerMode) {
    headers.push({
      title: t("container-browser.stars"),
      key: "stars",
      sortable: false,
      align: "end",
    });
  }
});

const filterView = () => {
  // If we're only showing starred items, we need to filter them
  const query = searchQuery.value;

  if (query === "" && showOnlyStarred.value === false) {
    filteredFilesList.value = [...files.value];
    return;
  } else {
    filteredFilesList.value = files.value.filter((item) => {
      if (!item.name) {
        return [];
      }
      return item.name.toLowerCase().includes(query.toLowerCase());
    });
  }

  if (showOnlyStarred.value) {
    filteredFilesList.value = files.value.filter((item) => {
      return isStarred(item.block);
    });
  }
};

const handleStarClick = (item) => {
  if (process.env.NODE_ENV === "development") {
    console.debug("Star clicked", item);
  }

  emit("star", item);
};

const handleDeleteClick = (item) => {
  if (process.env.NODE_ENV === "development") {
    console.debug("Delete clicked", item);
  }

  emit("delete", item);
};

const handleDownloadClick = (item) => {
  if (process.env.NODE_ENV === "development") {
    console.debug("Download clicked", item);
  }

  emit("download", item);
};

// Event handlers
const handleItemClick = (event, item) => {
  if (!props.selectionMode) {
    // We're not in selection mode, emit the itemClick event
    emit("itemClick", event, item);
    return;
  }

  if (event.shiftKey) {
    handleShiftClick(item);
  } else if (event.ctrlKey || event.metaKey) {
    handleCtrlClick(item);
  } else {
    handleRegularClick(item);
  }
};

const updateDescriptionOnFocusOut = async (item, isFocused) => {
  if (!isFocused) {
    try {
      await updateFileDescription(item.id, item.description);
    } catch (ex) {
      item.description = originalImageDescription.value;
      console.error("Error on update file description: ", ex);
      global.$error("An error occurred while updating the file description.");
    }
  } else {
    originalImageDescription.value = item.description;
  }
};

const isStarred = (blockId) => {
  if (props.stars.length > 0) {
    return props.stars.find((s) => s.starredBlock === blockId) !== undefined;
  }
  return false;
};

const closeBanner = (event) => {
  event.stopPropagation();
  emit("selectionBannerClosed");
};

const handleEmptyAreaClick = () => {
  console.debug("Empty area clicked");
  selectedItems.value = [];
  emit("selectionChanged", selectedItems.value);
};

const handleShiftClick = (item) => {
  let newItems = [];
  const finalItems = [...selectedItems.value];
  if (selectedItems.value.length === 0) {
    newItems.push(item);
  } else {
    const lastSelectedItem = finalItems[finalItems.length - 1];
    const startIndex = props.files.findIndex(
      (i) => i.id === lastSelectedItem.id,
    );
    const endIndex = props.files.findIndex((i) => i.id === item.id);
    const range = [startIndex, endIndex].sort((a, b) => a - b);
    newItems = props.files.slice(range[0], range[1] + 1);
  }

  for (const element of newItems) {
    if (finalItems.findIndex((item) => item.id === element.id) === -1) {
      finalItems.push(element);
    }
  }

  selectedItems.value = finalItems;
  emit("selectionChanged", selectedItems.value);
};

const handleCtrlClick = (item) => {
  const tempItems = [...selectedItems.value];
  const existingIndex = tempItems.findIndex((i) => i.id === item.id);
  if (existingIndex !== -1) {
    tempItems.splice(existingIndex, 1);
  } else {
    tempItems.push(item);
  }
  selectedItems.value = tempItems;
  emit("selectionChanged", selectedItems.value);
};

const handleRegularClick = (item) => {
  if (selectedItems.value.length === 1) {
    if (selectedItems.value[0].id === item.id) {
      selectedItems.value = [];
    } else {
      selectedItems.value = [item];
    }
  } else {
    selectedItems.value = [item];
  }
  emit("selectionChanged", selectedItems.value);
};

const getStarCount = (blockId) => {
  return props.stars.filter((star) => star.starredBlock === blockId).length;
};

const getCurrentDateTime = () => {
  return new Date().toLocaleString(undefined, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
};

const escapeFileName = (name) => {
  return name.replace(/[^a-z0-9]/gi, "_").toLowerCase();
};

const handleDownloadAllClick = () => {
  const starredFiles = files.value.filter((item) => {
    return isStarred(item.block);
  });

  const quenLikedCsvContent = starredFiles
    .map((file) => {
      return `${file.originalName.replace(/\.[^/.]+$/, "")}`;
    })
    .join(",");

  const fullFile = `Liked original file names:\n${quenLikedCsvContent}\n\nGenerated by www.JellyPic.com`;

  const blob = new Blob([fullFile], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  const escapedFileName = escapeFileName(props.containerName);
  link.setAttribute("href", url);
  link.setAttribute("download", escapedFileName + " file list.txt");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const hasStarredFiles = computed(() => {
  return files.value.some((item) => isStarred(item.block));
});
</script>

<style scoped>
.please-wait-positioning {
  position: fixed;
  bottom: 0; /* Posizionamento in basso */
  left: 50%; /* Allineamento al centro orizzontale */
  transform: translateX(-50%); /* Corregge lo scostamento */
  z-index: 9999; /* Sopra tutti gli altri contenuti */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Opzionale se vuoi limitare la larghezza */
  pointer-events: none; /* Non interferire con i click */
}
</style>
