import axios from "axios";
import { $useRemoteApi } from "@/plugins/globalProperties";

export const tagInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production" || $useRemoteApi
      ? "https://sinkit.graybay-4c50acea.northeurope.azurecontainerapps.io/"
      : "https://localhost:7265/",
  json: true,
});

export const getTags = async () => {
  return await tagInstance.get("/tag/all");
};

export const getTag = async (tagId) => {
  return await tagInstance.get(`/tag/${tagId}`);
};

export const getFileTags = async (fileIds) => {
  return await tagInstance.post("/tag/file-tags", fileIds);
};

export const addTag = async (tag) => {
  return await tagInstance.post("/tag", tag);
};

export const updateTag = async (tag) => {
  return await tagInstance.put("/tag", tag);
};

export const deleteTag = async (tagId) => {
  return await tagInstance.delete(`/tag/${tagId}`);
};

export const setTags = async (fileId, tags) => {
  const body = {
    identifiers: tags.map((t) => t.id),
  };

  return await tagInstance.post(`/file/${fileId}/tags/set`, body);
};

export const addTagToFiles = async (tagId, fileIds) => {
  const body = {
    identifiers: fileIds,
  };

  return await tagInstance.post(`/tag/${tagId}/add`, body);
};

export const removeTagFromFiles = async (tagId, fileIds) => {
  const body = {
    identifiers: fileIds,
  };

  return await tagInstance.post(`/tag/${tagId}/remove`, body);
};

export const getViewerTags = async (fileIds) => {
  return await tagInstance.post("/tag/viewer/tags", fileIds);
};

export const getViewerFileTags = async (fileIds) => {
  return await tagInstance.post("/tag/viewer/file-tags", fileIds);
};
