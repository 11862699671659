import axios from "axios";
import { $useRemoteApi } from "@/plugins/globalProperties";

// This service offer different methods to interact with the blocks API including
// their metadata. The metadata is a key-value store that can be used to store
// any arbitrary data

export const salesInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production" || $useRemoteApi
      ? "https://sales.graybay-4c50acea.northeurope.azurecontainerapps.io"
      : "https://localhost:7213/",
  json: true,
});

export const alive = async () => {
  return await salesInstance.get("health");
};

export const getPlanStats = async () => {
  return await salesInstance.get("plans/stats");
};

export const getCurrentPlan = async () => {
  return await salesInstance.get("plans/plan");
};

export const getHardPlan = async () => {
  return await salesInstance.get("plans/hardplan");
};

export const canWeAcceptNewUsers = async () => {
  return await salesInstance.get("dealer/canweacceptnewusers");
};

export const fileComplaint = async (complaint) => {
  return await salesInstance.post(
    "dealer/filecomplaint",
    JSON.stringify(complaint),
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};
