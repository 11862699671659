<template>
  <v-container class="my-16 py-16">
    <h1 class="text-h1" id="terms-index">All things legal</h1>
    <p></p>

    <h2 class="text-h2">Current Plan Limits</h2>
    <p>
      To ensure a smooth and enjoyable experience for all our users, we've
      implemented various anti-abuse systems. 🚀
    </p>

    <p>
      The first measure is a strict cap on the maximum number of users we can
      accept during these early stages of the app. This helps us prevent misuse
      and carefully audit the platform from both security and cost perspectives.
      🔒💰
    </p>

    <h3 class="text-h3">Platform Limitations</h3>
    <p>
      Below are some of the current limits that might impact your experience
      while using the platform. 🛠️
    </p>
    <p>
      <b>Please note:</b> These limits are temporary and will evolve as we grow!
    </p>
    <v-list density="dense">
      <v-list-item v-for="(value, key) in hardPlan" :key="key">
        <v-list-item-title>
          - <b>{{ key }}</b
          >: {{ value === 0 ? "Not defined" : value }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <h2 class="text-h2" id="privacy-policy">Privacy Policy</h2>
    <p></p>
    <h2 class="text-h2">Third party notices</h2>
    <p>
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy" target="_blank"
        >Privacy Policy</a
      >
      and
      <a href="https://policies.google.com/terms" target="_blank"
        >Terms of Service</a
      >
      apply.
    </p>

    <h2 class="text-h2" id="release-history">Release history and change log</h2>
    <p>List of app releases and changes.</p>

    <v-list>
      <v-list-item>
        <template v-slot:prepend>
          <v-icon icon="mdi-link-lock"></v-icon>
        </template>
        <v-list-item-title
          ><b>V 1.0.0</b> Beta 1 - Feburary 1<sup>st</sup>
          2025</v-list-item-title
        >
        <v-list-item-subtitle
          >First release to the public - VN only</v-list-item-subtitle
        >
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getHardPlan } from "@/api/sales.client";

const hardPlan = ref(null);

const fetchHardPlan = async () => {
  try {
    const response = await getHardPlan();
    hardPlan.value = response.data;
  } catch (error) {
    console.error("Error fetching hard plan:", error);
    throw error;
  }
};

onMounted(() => {
  fetchHardPlan();
});
</script>

<style scoped>
/* Add your styles here */
</style>
