import axios from "axios";
import { $useRemoteApi } from "@/plugins/globalProperties";

const baseURL =
  process.env.NODE_ENV === "production" || $useRemoteApi
    ? "https://filevault.graybay-4c50acea.northeurope.azurecontainerapps.io/"
    : "https://localhost:7922/";

let abortController = new AbortController();
const getSignal = () => {
  return abortController.signal;
};

export const imageInstance = axios.create({
  baseURL: baseURL,
});

export const isCancelError = (error) => {
  return axios.isCancel(error);
};

export const abortRequest = () => {
  abortController.abort("User cancelled request");
  abortController = new AbortController();
};

export const uploadImage = async (containerId, image, onUploadProgress) => {
  const formData = new FormData();
  formData.append("image", image);
  formData.append("created", image.created.toISOString());
  formData.append("modified", image.modified.toISOString());

  return await imageInstance.post(`image/${containerId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: onUploadProgress,
    signal: getSignal(),
  });
};

export const uploadImages = async (containerId, images, onUploadProgress) => {
  if (process.env.NODE_ENV === "development") {
    const totalSize =
      images.reduce((acc, image) => acc + image.size, 0) / (1024 * 1024);
    console.debug(
      `Uploading ${images.length} files with a total size of ${totalSize} Mb`,
    );
  }

  let formData = new FormData();
  formData.append("containerId", containerId);
  images.forEach((image) => {
    formData.append("images", image);
    formData.append("created", image.created.toISOString());
    formData.append("modified", image.modified.toISOString());
  });

  return await imageInstance.post("image", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: onUploadProgress,
    signal: abortController.signal,
  });
};

export const deleteImage = async (imageId) => {
  return await imageInstance.delete(`image/${imageId}`);
};

export const deleteImages = async (imageIds) => {
  return await imageInstance.post("image/delete", imageIds);
};

export const getImage = async (imagePath) => {
  if (isInt(imagePath)) {
    return await imageInstance.get(`image/${imagePath}`, {
      responseType: "blob",
    });
  } else {
    const path = encodeURIComponent(imagePath);
    return await imageInstance.get(`image?filePath=${path}`, {
      responseType: "blob",
    });
  }
};

export const getArchive = async (imagePathes, containerName) => {
  const model = {
    pathes: imagePathes,
    albumName: containerName,
  };

  return await imageInstance.post("image/archive", model, {
    responseType: "blob",
  });
};

export const imageUrl = (imagePath, width = 0) => {
  const setWidth = width > 0 ? `?width=${width}&` : "?";

  if (isInt(imagePath)) {
    return `${baseURL}image/${imagePath}${setWidth}`;
  } else {
    const path = encodeURIComponent(imagePath);
    return `${baseURL}image${setWidth}filePath=${path}`;
  }
};

const isInt = (value) => {
  let x;
  if (isNaN(value)) {
    return false;
  }
  x = parseFloat(value);
  return (x | 0) === x;
};
