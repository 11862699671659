<template>
  <ErrorBoundary>
    <v-layout class="rounded-md">
      <router-view />
      <v-tooltip
        v-if="xs || sm"
        location="bottom"
        transition="fade-transition"
        open-on-hover
      >
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            icon
            color="warning"
            class="fab-bottom-right"
            density="compact"
            size="small"
          >
            <v-icon color="white" size="small">mdi-alert-circle</v-icon>
          </v-btn>
        </template>
        <span>
          For the best experience, please use a larger screen. A mobile version
          is coming soon!
        </span>
      </v-tooltip>
    </v-layout>
  </ErrorBoundary>
</template>

<script setup>
import ErrorBoundary from "@/components/ErrorBoundary.vue";
import { onMounted } from "vue";
import { useTheme } from "vuetify";
import { useDisplay } from "vuetify";

const { xs, sm } = useDisplay();
const theme = useTheme();

onMounted(() => {
  // set the app theme according to the stored values on startup
  const userSelectedTheme = localStorage.getItem("theme");
  const systemTheme = localStorage.getItem("use-system-theme");

  if (systemTheme && systemTheme === "true") {
    const updatedTheme = window.matchMedia("(prefers-color-scheme: dark)")
      .matches
      ? "dark"
      : "light";
    theme.global.name.value = updatedTheme;
  } else if (userSelectedTheme) {
    if (userSelectedTheme !== theme.global.name.value) {
      theme.global.name.value = userSelectedTheme;
    }
  }
});
</script>

<style>
.no-select {
  user-select: none;
}

.grecaptcha-badge {
  visibility: hidden;
  opacity: 0;
  position: absolute;
}
</style>

<style scoped>
.fab-bottom-right {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 9999;
}
</style>
