<template>
  <v-container>
    <v-row dense>
      <v-col cols="12" v-if="xs || sm">
        <div class="text-center text-h5 mt-4">{{ title }}</div>
      </v-col>
      <v-col cols="6" class="d-flex flex-column">
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          :label="t('container-browser-head.search')"
          variant="plain"
          width="300"
          rounded
          clearable
          hint="Search by name"
        ></v-text-field>
      </v-col>
      <v-col
        cols="6"
        class="d-flex justify-end align-center"
        id="container-browser-head-actions"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ props }">
            <v-btn
              variant="plain"
              density="comfortable"
              @click="toggleShowOnlyStarred"
              :color="showOnlyStarred ? 'error' : ''"
              v-bind="props"
              id="container-browser-head-actions-starred"
            >
              <v-icon>{{
                showOnlyStarred ? "mdi-heart" : "mdi-heart-outline"
              }}</v-icon>
            </v-btn>
          </template>
          <span>{{
            t("container-browser-head.show-liked-pictures-tooltip")
          }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ props }">
            <v-btn
              variant="plain"
              density="comfortable"
              @click="toggleSortOrder"
              v-if="viewMode === 'grid'"
              v-bind="props"
              id="container-browser-head-actions-sort"
            >
              <v-icon>{{
                sortOrder === "asc"
                  ? "mdi-sort-ascending"
                  : "mdi-sort-descending"
              }}</v-icon>
            </v-btn>
          </template>
          <span>{{ t("container-browser-head.toggle-sorting-tooltip") }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ props }">
            <v-btn
              density="comfortable"
              variant="plain"
              @click="toggleViewMode"
              v-bind="props"
              id="container-browser-head-actions-view-mode"
            >
              <v-icon>{{
                viewMode === "list" ? "mdi-view-grid" : "mdi-view-list"
              }}</v-icon>
            </v-btn>
          </template>
          <span>{{
            t("container-browser-head.toggle-view-mode-tooltip")
          }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { defineModel, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import { useDisplay } from "vuetify";

const { t } = useI18n();
const { xs, sm } = useDisplay();

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
});

const showOnlyStarred = defineModel("showOnlyStarred", {
  type: Boolean,
});

const search = defineModel("search", {
  type: String,
});

const sortOrder = defineModel("sortOrder", {
  type: String,
  default: "asc",
});

const viewMode = defineModel("viewMode", {
  type: String,
  default: "grid",
});

const toggleSortOrder = () => {
  sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
};

const toggleViewMode = () => {
  viewMode.value = viewMode.value === "grid" ? "list" : "grid";
};

const toggleShowOnlyStarred = () => {
  showOnlyStarred.value = !showOnlyStarred.value;
};
</script>

<style scoped>
/* Empty style */
</style>
