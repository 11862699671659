<template>
  <v-container
    class="d-flex no-select align-center justify-start pa-0 logo-container"
    style="max-width: 150px"
    @click="$router.push('/')"
  >
    <v-img src="@/assets/jellypic-symbol.svg" width="32" height="32" />
    <v-img
      src="@/assets/jellypic-text.svg"
      width="100"
      height="32"
      class="ml-2 d-none d-sm-block"
    />
  </v-container>
</template>

<script setup>
import { onMounted } from "vue";

// life cycle Hooks
onMounted(() => {});
</script>

<style scoped>
.logo-container {
  /* filter: drop-shadow(0 0 1px rgba(255, 255, 255, 0.5)); */
}
</style>
