<template>
  <transition name="fade">
    <v-container v-if="isLoadingLocal">
      <v-container class="box px-4 py-2 text-caption text-center">
        {{ message }}
      </v-container>
    </v-container>
  </transition>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { isLoading } from "@/api"; // Import the global loading state

const messages = [
  "I'm working please be patient...",
  "Working diligently to get this done...",
  "Processing your request, please be patient...",
  "Hold on, we're almost there...",
  "Waiting for the magic to happen...",
  "Loading your data, just a moment...",
  "Please wait...",
  "Hang tight, we're getting it done...",
  "Fetching your data, just a second...",
  "Thinking hard about your request...",
  "Crunching the numbers, hold on...",
  "Making sure everything's perfect for you...",
  "Patience is a virtue, almost there...",
  "Loading the magic, one moment...",
  "We're on it, just give us a second...",
  "Good things take time, please wait...",
  "Your request is in progress, hang tight...",
];
const message = ref("");
const isLoadingLocal = ref(false);

function changeMessage() {
  message.value = messages[Math.floor(Math.random() * messages.length)];
}

onMounted(() => {});

watch(isLoading, (newVal) => {
  if (newVal) {
    setTimeout(() => {
      if (isLoading.value) {
        changeMessage();
        isLoadingLocal.value = true;
      }
    }, 500);
  } else {
    isLoadingLocal.value = false;
  }
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.box {
  background: hsl(0, 0%, 100%);
  padding: 16px 24px;
  border-radius: 16px;
  color: black;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01);
  position: relative;
  max-width: 400px;

  &::after {
    position: absolute;
    content: "";
    top: 10px;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    transform: scale(1) translateZ(0);
    filter: blur(10px);
    background: linear-gradient(
      to left,
      #ff5770,
      #e4428d,
      #c42da8,
      #9e16c3,
      #6501de,
      #9e16c3,
      #c42da8,
      #e4428d,
      #ff5770
    );
    background-size: 200% 200%;
    animation: animateGlow 1.25s linear infinite;
  }
}

@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}
</style>
